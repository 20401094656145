import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { faChevronRight } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import Typography from '@mui/material/Typography'
import makeStyles from '@mui/styles/makeStyles'
import { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import useNavigateWithLayoutBlocker from '@/common/hooks/useNavigateWithLayoutBlocker'

type CustomBreadcrumbsProps = {
  lastBreadcrumbSuffix?: string
  currentStep?: number
  steps?: Array<{ name: string; icon: JSX.Element }>

  setCurrentStep?: (step: number) => void
}

const breadcrumbList = [
  { label: 'breadcrumbs.campaigns', url: '/campaign' },
  { label: 'breadcrumbs.campaigns', url: '/training-campaigns' },

  { label: 'breadcrumbs.campaignDashboard', url: '/campaign/:id' },
  { label: 'breadcrumbs.campaignDashboard', url: '/training-campaigns/:id' },

  { label: 'breadcrumbs.campaigns', url: '/campaigns' },
  { label: 'breadcrumbs.libraries', url: '/content-library', overwriteUrl: '/content-library/videos' },
  { label: 'breadcrumbs.videos', url: '/content-library/videos' },
  { label: 'breadcrumbs.simulations', url: '/content-library/simulations' },
  {
    label: 'breadcrumbs.simulations',
    url: '/content-library/simulations-package',
    overwriteUrl: '/content-library/simulations',
  },
  { label: 'breadcrumbs.upload', url: '/content-library/videos/create' },
  { label: 'breadcrumbs.preview', url: '/content-library/videos/:id' },
  { label: 'breadcrumbs.edit', url: '/content-library/videos/:id/edit' },
  { label: 'breadcrumbs.create', url: '/content-library/simulations/create' },
  {
    label: 'breadcrumbs.aisimulations',
    url: '/content-library/simulations/ai-simulation-builder',
  },
  { label: 'breadcrumbs.preview', url: '/content-library/simulations/:id' },
  { label: 'breadcrumbs.preview', url: '/content-library/simulations-package/:id' },

  { label: 'breadcrumbs.edit', url: '/content-library/simulations/edit/:id' },

  { label: 'breadcrumbs.recipients', url: '/recipients', overwriteUrl: '/recipients/members' },
  { label: 'breadcrumbs.members', url: '/recipients/members' },
  { label: 'breadcrumbs.members', url: '/recipients/users', overwriteUrl: '/recipients/members' },
  { label: 'breadcrumbs.groups', url: '/recipients/groups' },
  { label: 'breadcrumbs.dynamicGroups', url: '/recipients/dynamic-groups' },
  { label: 'breadcrumbs.offices', url: '/recipients/offices' },
  { label: 'breadcrumbs.activeDirectory', url: '/recipients/active-directory' },
  { label: 'breadcrumbs.profile', url: '/recipients/groups/:id' },
  { label: 'breadcrumbs.profile', url: '/recipients/dynamic-groups/:id' },
  { label: 'breadcrumbs.profile', url: '/recipients/users/:id' },
  { label: 'breadcrumbs.profile', url: '/recipients/offices/:id' },
  { label: 'breadcrumbs.settings', url: '/settings', overwriteUrl: '/settings/configurations' },
  { label: 'breadcrumbs.configurations', url: '/settings/configurations' },
  { label: 'breadcrumbs.settings', url: '/settings', overwriteUrl: '/settings/admins' },
  { label: 'breadcrumbs.admins', url: '/settings/admins' },

  { label: 'breadcrumbs.adminProfile', url: '/settings/admins/:id' },
  { label: 'breadcrumbs.settings', url: '/settings', overwriteUrl: '/settings/admins' },
  { label: 'breadcrumbs.admins', url: '/settings/admins' },
  { label: 'breadcrumbs.settings', url: '/settings', overwriteUrl: '/settings/plugins' },
  { label: 'breadcrumbs.plugins', url: '/settings/plugins' },
  { label: 'breadcrumbs.settings', url: '/settings', overwriteUrl: '/settings/allowlisting' },
  { label: 'breadcrumbs.allowlisting', url: '/settings/allowlisting' },
  { label: 'breadcrumbs.settings', url: '/settings', overwriteUrl: '/settings/integrations' },
  { label: 'breadcrumbs.integrations', url: '/settings/integrations' },

  { label: 'breadcrumbs.activityLogs', url: '/client-activity' },
  { label: 'breadcrumbs.responseConsole', url: '/response-console' },
]

export type BreadcrumbNode = {
  label: string
  url: string
  overwriteUrl?: string
}

const CustomBreadcrumbs: FC<CustomBreadcrumbsProps> = ({
  lastBreadcrumbSuffix,
  currentStep,
  steps,
  setCurrentStep,
}) => {
  const classes = useStyles()
  const navigateTo = useNavigateWithLayoutBlocker()
  const location = useLocation()
  const { t } = useTranslation()

  const handleClick = (node: BreadcrumbNode) => {
    switch (node.url) {
      case '/content-library/simulations/ai-simulation-builder':
        setCurrentStep && setCurrentStep(0)
        break
      default:
        navigateTo(node?.overwriteUrl || node.url)
    }
  }

  const nodes = useMemo(() => {
    const pathParts = location.pathname.split('/').filter((part) => part !== '')

    const breadcrumbs: BreadcrumbNode[] = []
    let currentPath = ''

    for (const part of pathParts) {
      currentPath += `/${part}`

      const breadcrumb = breadcrumbList.find(({ url }) => {
        const regex = new RegExp('^' + url.replace(/:[^/]+/g, '[^/]+') + '$')
        return regex.test(currentPath)
      })

      if (breadcrumb) {
        const urlWithParams = currentPath
        breadcrumbs.push({ ...breadcrumb, url: urlWithParams })
      }
    }

    return breadcrumbs
  }, [location.pathname])

  const handleStepClick = (stepIndex: number) => {
    setCurrentStep && setCurrentStep(stepIndex)
  }

  const renderNodes = () => {
    const renderedRoutes = nodes.map((node, index) => (
      // last one is semi-bold:
      <div
        className={index === nodes.length - 1 && !steps ? classes.currentBreadcrumbLink : classes.breadcrumbLink}
        key={index}
        onClick={() => handleClick(node)}>
        {t(node.label)}
      </div>
    ))

    if (steps && typeof currentStep !== 'undefined') {
      const renderedSteps = steps.slice(0, currentStep + 1).map((step, index) => {
        return (
          <Typography
            key={`step-${index}`}
            onClick={() => {
              if (index !== currentStep) handleStepClick(index)
            }}
            className={index === currentStep ? classes.currentBreadcrumbLink : classes.breadcrumbLink}>
            {step.name}
          </Typography>
        )
      })
      return [...renderedRoutes, ...renderedSteps]
    }

    return renderedRoutes
  }

  return (
    <Breadcrumbs
      separator={<FontAwesomeIcon icon={faChevronRight as IconProp} />}
      className={classes.breadcrumbs}
      aria-label="breadcrumb">
      {renderNodes()}
    </Breadcrumbs>
  )
}

const useStyles = makeStyles((theme) => ({
  breadcrumbs: {
    backgroundColor: 'rgba(255, 255, 255, 0.7)',
    color: '#294D55',
  },
  breadcrumbLink: {
    cursor: 'pointer',
    fontWeight: theme.typography.fontWeightRegular,
  },
  currentBreadcrumbLink: {
    cursor: 'pointer',
    fontWeight: theme.typography.fontWeightSemiBold,
  },
  arrowIcon: {
    marginLeft: theme.spacing(-1.3),
    marginRight: theme.spacing(-1.3),
  },
}))

export default CustomBreadcrumbs
