import { Navigate, Route, BrowserRouter as Router, Routes } from 'react-router-dom'

import { App, useApps } from '@/api/apps/apps'
import { useClientDetails } from '@/api/client/client'
import Layout from '@/common/components/Layout/Layout'
import { getAppsObject } from '@/common/utils/app-utils'
import { useAuth } from '@/context/Auth'
import { ContentLibraryProvider } from '@/context/ContentLibraryContext/ContentLibraryContext'
import AccountCenter from '@/pages/AccountCenter'
import LoginContainer from '@/pages/Auth/LoginContainer'
import ResetPasswordContainer from '@/pages/Auth/ResetPasswordContainer'
import Signup from '@/pages/Auth/Signup'
import UserUnlock from '@/pages/Auth/UserUnlock'
import VerifyEmail from '@/pages/Auth/VerifyEmail'
import CampaignDashboard from '@/pages/CampaignDashboard'
import Campaigns from '@/pages/Campaigns/Campaigns'
import ChooseClient from '@/pages/ChooseClient/ChooseClient'
import ClientActivityDashboard from '@/pages/ClientActivity/ClientActivityDashboard'
import AiSimulationBuilder from '@/pages/ContentLibrary/AiSimulationBuilder/AiSimulationBuilder'
import ContentLibrary from '@/pages/ContentLibrary/ContentLibrary'
import SimulationPackagePage from '@/pages/ContentLibrary/SimulationsLibrary/SimulationPackagePage'
import SimulationPage from '@/pages/ContentLibrary/SimulationsLibrary/SimulationPage/SimulationPage'
import CreateVideoPage from '@/pages/ContentLibrary/VideosLibrary/CreateVideoPage/CreateVideoPage'
import EditVideoPage from '@/pages/ContentLibrary/VideosLibrary/EditVideoPage/EditVideoPage'
import VideoPage from '@/pages/ContentLibrary/VideosLibrary/VideoPage/VideoPage'
import CreateTrainingCampaign from '@/pages/CreateTrainingCampaign/CreateTrainingCampaign'
import Dashboard from '@/pages/Dashboard/Dashboard'
import DynamicGroupProfile from '@/pages/DynamicGroupProfile'
import ExpiredCampaign from '@/pages/ExpiredCampagin/ExpiredCampaign'
import TrainingCampaignDemoView from '@/pages/Genesis/TrainingCampaignDemoView'
import GroupProfile from '@/pages/GroupProfile/index'
import Microtraining from '@/pages/Microtraining'
import OfficeProfile from '@/pages/OfficeProfile/OfficeProfile'
import Onboarding from '@/pages/Onboarding/Onboarding'
import PricingCalculator from '@/pages/PricingCalculator'
import Quiz from '@/pages/Quiz/Quiz'
import ReportsOnboarding from '@/pages/ReportsOnboarding/ReportsOnboarding'
import ResponseConsole from '@/pages/ResponseConsole/ResponseConsole'
import ResponseConsoleReport from '@/pages/ResponseConsoleReport/ResponseConsoleReport'
import AcceptInvitationPage from '@/pages/Settings/Admins/AcceptInvitationPage'
import AdminProfile from '@/pages/Settings/Admins/AdminProfile'
import MicrosoftEntraIdInstructions from '@/pages/Settings/Integrations/MicrosoftEntraIdInstructions'
import Settings from '@/pages/Settings/Settings'
import SimulationBuilder from '@/pages/SimulationBuilder'
import TrainingCampaignDashboard from '@/pages/TrainingCampaignDashboard'
import TrainingCampaignView from '@/pages/TrainingCampaignView/TrainingCampaignView'
import TrainingComplete from '@/pages/TrainingComplete/TrainingComplete'
import UserProfile from '@/pages/UserProfile/UserProfile'
import Users from '@/pages/Users/Users'
import { OrganizationAccountType } from '@/types/organizations'
import ProtectedRoute from './ProtectedRoute'
import ThemeComponentsDevPreview from '@/pages/DevPreviews/ThemeComponentsDevPreview'
import ThemeFieldComponentsPreview from '@/pages/DevPreviews/ThemeFieldComponentsPreview'

const LoginRoutes = ({ children, redirectTo }) => {
  const { user } = useAuth()
  const { data: client } = useClientDetails()

  if (!user) return children

  if (user?.has_multiple_clients || client?.type !== OrganizationAccountType.client) redirectTo = '/account-center'

  return <Navigate to={redirectTo} replace />
}

const AppRoutes = () => {
  const { data: apps } = useApps()
  const appsObject: { [key: string]: App } = getAppsObject(apps || [])

  return (
    <Router>
      <Routes>
        {/* theme-componenets-preview - DEV */}
        {/* TODO: comment after testing! */}
        {/* <Route path="/theme-componenets-preview" element={<ThemeComponentsDevPreview />} />
        <Route path="/theme-componenets-fields-preview" element={<ThemeFieldComponentsPreview />} /> */}

        <Route
          path="/login"
          element={
            <LoginRoutes redirectTo="/">
              <LoginContainer />
            </LoginRoutes>
          }
        />
        <Route
          path="/signup"
          element={
            <LoginRoutes redirectTo="/">
              <Signup />
            </LoginRoutes>
          }
        />
        <Route
          path="/reset-password/*"
          element={
            <LoginRoutes redirectTo="/">
              <ResetPasswordContainer />
            </LoginRoutes>
          }
        />
        <Route
          path="/verify-email"
          element={
            <LoginRoutes redirectTo="/">
              <VerifyEmail />
            </LoginRoutes>
          }
        />
        <Route
          path="/unlock-user"
          element={
            <LoginRoutes redirectTo="/">
              <UserUnlock />
            </LoginRoutes>
          }
        />
        <Route path="/expired-campaign/:token" element={<ExpiredCampaign />} />
        <Route path="/feedback/preview/:simulationId" element={<Microtraining isPreview />} />
        <Route path="/feedback/:token" element={<Microtraining />} />
        <Route path="/content/training-campaign/:token" element={<TrainingCampaignView />} />
        <Route path="/content/completed-training" element={<TrainingComplete />} />
        <Route path="/content/quiz/:campaignToken/:quizId" element={<Quiz />} />
        <Route
          path="/choose-client"
          element={
            <ProtectedRoute>
              <ChooseClient />
            </ProtectedRoute>
          }
        />
        <Route
          path="/account-center"
          element={
            <ProtectedRoute>
              <AccountCenter />
            </ProtectedRoute>
          }
        />
        <Route
          path="/account-center/pricing-calculator"
          element={
            <ProtectedRoute>
              <PricingCalculator />
            </ProtectedRoute>
          }></Route>

        <Route path="/genesis/training-campaign-demo/" element={<TrainingCampaignDemoView />} />
        {/*<Route*/}
        {/*  path="/genesis/training-campaign-demo/quiz/:campaignToken/:quizId"*/}
        {/*  element={<QuizDemo />}*/}
        {/*/>*/}
        <Route element={<Layout />}>
          <Route
            path="/"
            element={
              <ProtectedRoute enabled={appsObject?.simulator?.enabled}>
                <Dashboard />
              </ProtectedRoute>
            }
          />
          <Route
            path="/campaign"
            element={
              <ProtectedRoute>
                <Navigate to="/campaigns" />
              </ProtectedRoute>
            }
          />
          <Route
            path="/training-campaigns"
            element={
              <ProtectedRoute>
                <Navigate to="/campaigns" />
              </ProtectedRoute>
            }
          />
          <Route
            path="/campaign/:_id"
            element={
              <ProtectedRoute enabled={appsObject?.simulator?.enabled}>
                <CampaignDashboard />
              </ProtectedRoute>
            }
          />
          <Route
            path="/training-campaigns/new"
            element={
              <ProtectedRoute enabled={appsObject?.simulator?.enabled}>
                <CreateTrainingCampaign />
              </ProtectedRoute>
            }
          />
          <Route
            path="/training-campaigns/edit/:_id"
            element={
              <ProtectedRoute enabled={appsObject?.simulator?.enabled}>
                <CreateTrainingCampaign />
              </ProtectedRoute>
            }
          />
          <Route
            path="/training-campaigns/:_id"
            element={
              <ProtectedRoute enabled={appsObject?.simulator?.enabled}>
                <TrainingCampaignDashboard />
              </ProtectedRoute>
            }
          />
          <Route
            path="/reports-onboarding"
            element={
              <ProtectedRoute enabled>
                <ReportsOnboarding />
              </ProtectedRoute>
            }
          />
          <Route
            path="/response-console"
            element={
              <ProtectedRoute enabled={appsObject?.reports?.enabled}>
                <ResponseConsole />
              </ProtectedRoute>
            }
          />
          <Route
            path="/response-report/:_id"
            element={
              <ProtectedRoute enabled={appsObject?.reports?.enabled}>
                <ResponseConsoleReport />
              </ProtectedRoute>
            }
          />
          <Route
            path="/onboarding/*"
            element={
              <ProtectedRoute>
                <Onboarding />
              </ProtectedRoute>
            }
          />
          <Route
            path="/recipients/*"
            element={
              <ProtectedRoute>
                <Users />
              </ProtectedRoute>
            }
          />
          <Route
            path="/recipients/users/:_id"
            element={
              <ProtectedRoute>
                <UserProfile />
              </ProtectedRoute>
            }
          />
          <Route
            path="/recipients/groups/:_id"
            element={
              <ProtectedRoute>
                <GroupProfile />
              </ProtectedRoute>
            }
          />
          <Route
            path="/recipients/dynamic-groups/:_id"
            element={
              <ProtectedRoute>
                <DynamicGroupProfile />
              </ProtectedRoute>
            }
          />
          <Route
            path="/recipients/offices/:_id"
            element={
              <ProtectedRoute>
                <OfficeProfile />
              </ProtectedRoute>
            }
          />
          <Route
            path="/settings/*"
            element={
              <ProtectedRoute>
                <Settings />
              </ProtectedRoute>
            }
          />
          <Route
            path="/settings/admins/:_id"
            element={
              <ProtectedRoute>
                <AdminProfile />
              </ProtectedRoute>
            }
          />
          <Route
            path="/client-activity"
            element={
              <ProtectedRoute>
                <ClientActivityDashboard />
              </ProtectedRoute>
            }
          />
          <Route
            path="/content-library/videos/create"
            element={
              <ProtectedRoute>
                <CreateVideoPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/content-library/videos/:id/edit"
            element={
              <ProtectedRoute>
                <EditVideoPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/content-library/videos/:id"
            element={
              <ProtectedRoute>
                <ContentLibraryProvider>
                  <VideoPage />
                </ContentLibraryProvider>
              </ProtectedRoute>
            }
          />
          <Route
            path="/content-library/simulations/create"
            element={
              <ProtectedRoute>
                <SimulationBuilder />
              </ProtectedRoute>
            }
          />
          <Route
            path="/content-library/simulations/edit/:_id"
            element={
              <ProtectedRoute>
                <SimulationBuilder />
              </ProtectedRoute>
            }
          />
          <Route
            path="/content-library/simulations/ai-simulation-builder"
            element={
              <ProtectedRoute>
                <AiSimulationBuilder />
              </ProtectedRoute>
            }
          />
          <Route
            path="/content-library/simulations/:id"
            element={
              <ProtectedRoute>
                <ContentLibraryProvider>
                  <SimulationPage />
                </ContentLibraryProvider>
              </ProtectedRoute>
            }
          />
          <Route
            path="/content-library/simulations-package/:id"
            element={
              <ProtectedRoute>
                <ContentLibraryProvider>
                  <SimulationPackagePage />
                </ContentLibraryProvider>
              </ProtectedRoute>
            }
          />
          <Route
            path="/content-library/*"
            element={
              <ProtectedRoute>
                <ContentLibraryProvider>
                  <ContentLibrary />
                </ContentLibraryProvider>
              </ProtectedRoute>
            }
          />
          <Route
            path="/campaigns"
            element={
              <ProtectedRoute>
                <Campaigns />
              </ProtectedRoute>
            }
          />

          <Route path="*" element={<Navigate to="/" />} />
        </Route>
        <Route path="/accept-invitation" element={<AcceptInvitationPage />} />
        <Route path="/settings/integrations/entra-id-istructions" element={<MicrosoftEntraIdInstructions />} />
      </Routes>
    </Router>
  )
}

export default AppRoutes
