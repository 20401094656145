import { useMutation, useQueryClient } from '@tanstack/react-query'
import axios from 'axios'
import { useQueryCache } from 'react-query'

import { API_URL } from '@/api/constants'
import { CurrentUser } from './current-user'

const editProfile = async (profilePayload: CurrentUser) => {
  const { data } = await axios.put(`${API_URL}/profile/`, profilePayload, {
    withCredentials: true,
  })

  return data
}

export const useEditProfile = () => {
  const queryClient = useQueryClient()
  const queryCache = useQueryCache()

  return useMutation({
    mutationFn: editProfile,
    onSuccess: async () => {
      queryCache.invalidateQueries(['user'])
      queryClient.invalidateQueries({
        queryKey: ['admin'],
      })
    },
  })
}
