import { useChangePassword } from '@/api/profile/change-password'
import LoadingButton from '@mui/lab/LoadingButton'
import { Avatar, Box, Chip, Divider, Paper, styled, Typography } from '@mui/material'
import dayjs from 'dayjs'
import { Form, Formik } from 'formik'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
//@ts-ignore
import * as Yup from 'yup'

import { useDisableAdmin } from '@/api/admins/disable-admin'
import { useEnableAdmin } from '@/api/admins/enable-admin'
import { useAdmin } from '@/api/admins/get'
import { PHONE_NUMNER_E164_REGEX, US_DATE_WITH_MONTH_NAME } from '@/api/constants'
import { CurrentUser } from '@/api/profile/current-user'
import { useEditProfile } from '@/api/profile/edit'
import { getErrorMessage } from '@/api/utils/get-error'
import CustomBreadcrumbs from '@/common/components/Breadcrumbs/Breadcrumbs'
import PasswordField from '@/common/components/Inputs/PasswordField'
import PhoneNumberField from '@/common/components/Inputs/PhoneNumberField/index.'
import TextField from '@/common/components/Inputs/TextFieldV2'
import MoreMenu from '@/common/components/MoreMenu/MoreMenu'
import useToast from '@/common/hooks/useToast'
import { useAuth } from '@/context/Auth'
import { theme } from '@/theme/theme'
import { Admin, Status } from '@/types/admins'
import AdminStatusPopup from '../AdminStatusPopup'

const AdminProfile = () => {
  const { _id } = useParams()
  const { user } = useAuth()
  const { data: adminData, isFetching } = useAdmin({ id: _id })
  const { t } = useTranslation()

  const [disableWarningModalOpen, setDisableWarningModalOpen] = useState(false)

  const isCurrnetUser = user?.id === adminData?._id
  const [changePassword] = useChangePassword()
  const { mutateAsync: enableAdmin } = useEnableAdmin()
  const { mutateAsync: disableAdmin } = useDisableAdmin()

  const memberValidationSchema = Yup.object().shape({
    full_name: Yup.string().required('Name is required'),
    username: Yup.string().required('Email is required'),
    phone_number: Yup.string()
      .matches(PHONE_NUMNER_E164_REGEX, 'Invalid phone number')
      .required('Phone number is required'),
  })

  const changePasswordValidationSchema = Yup.object().shape({
    current_password: Yup.string().test(
      'current-password-check',
      'Current password is required',
      function (this: Yup.TestContext, value: string | undefined) {
        const { new_password, confirm_new_password } = this.parent
        return new_password || confirm_new_password ? !!value && value?.length >= 8 : true
      }
    ),
    confirm_new_password: Yup.string().test(
      'confirm-new-password-check',
      "Passwords don't match",
      function (this: Yup.TestContext, value: string | undefined) {
        const { new_password } = this.parent
        if (!new_password && !value) return true
        return value === new_password || this.createError({ message: "Passwords don't match" })
      }
    ),
  })

  const { mutateAsync: editProfile } = useEditProfile()
  const { successToast, errorToast } = useToast()

  const handleEnableAdmin = useCallback(
    async (admin: Admin) => {
      try {
        await enableAdmin(admin._id)
        successToast(t('settings.admins.table.adminEnabled'))
      } catch (e) {
        errorToast(t('settings.admins.table.errors.failedToEnable'))
      }
    },
    [enableAdmin, successToast, errorToast, t]
  )

  const handleDisableAdmin = useCallback(async () => {
    try {
      await disableAdmin(adminData?._id || '')
      setDisableWarningModalOpen(false)
      successToast(t('settings.admins.table.adminDisabled'))
    } catch (e) {
      errorToast(t('settings.admins.table.errors.failedToDisable'))
    }
  }, [disableAdmin, successToast, errorToast, t])

  const handleSubmitAdminDetails = async (values: Partial<CurrentUser>) => {
    if (!adminData?._id) return

    try {
      const adminPayload = {
        id: adminData._id,
        email: values.username,
        ...values,
      }

      // TODO Replace CurrentUser with ProfileRequest type
      await editProfile(adminPayload as CurrentUser)
      successToast(t(`settings.admins.profile.updateSuccessToast`))
    } catch (e) {
      errorToast(t(`settings.admins.profile.updateErrorToast`))
    }
  }

  const handleSubmitChangePassword = async (values: { current_password: string; new_password: string }) => {
    try {
      await changePassword(values)
      successToast(t(`settings.admins.profile.passwordSuccessToast`))
    } catch (e) {
      const errorMessage = getErrorMessage(e)
      errorToast(errorMessage || t(`settings.admins.profile.passwordErrorToast`))
    }
  }

  return (
    <>
      <Box padding={theme.spacing(1, 4)} minWidth={theme.spacing(88)}>
        <CustomBreadcrumbs />
        {!isFetching && adminData && (
          <StyledPaper elevation={1}>
            <StyledTitle>{t(`settings.admins.profile.adminDetails`)}</StyledTitle>
            <Box display="flex" padding={theme.spacing(2, 0, 2, 2)} gap={theme.spacing(2)} alignItems="center">
              <Avatar sx={{ background: theme.palette.cyan[500], width: '72px', height: '72px' }}>
                <Typography fontSize={48} color={theme.palette.blueDianne[900]}>
                  {adminData.full_name ? adminData.full_name[0] : ''}
                </Typography>
              </Avatar>
              <Box display="flex" flexDirection="column" gap={theme.spacing(1)} flexWrap={'wrap'}>
                <Box display="flex" alignItems="center" gap={theme.spacing(1)} flexWrap={'wrap'}>
                  <Typography whiteSpace="nowrap" fontWeight={theme.typography.fontWeightMedium}>
                    {t(`settings.admins.profile.adminState`)}
                  </Typography>
                  <Avatar
                    sx={{
                      background: adminData.status === Status.enabled ? 'green' : 'red',
                      width: '8px',
                      height: '8px',
                    }}>
                    {' '}
                  </Avatar>
                  <Typography> {t(`settings.admins.table.${adminData.status}`)}</Typography>
                  <MoreMenu
                    items={[
                      {
                        label: t('settings.admins.table.enabled'),
                        textColor: '',
                        onClick: () => {
                          handleEnableAdmin(adminData)
                        },
                      },
                      {
                        label: t('settings.admins.table.disabled'),
                        textColor: '',
                        onClick: () => {
                          setDisableWarningModalOpen(true)
                        },
                      },
                    ]}
                    ariaLabel={'settings.admins.table.moreActions'}></MoreMenu>
                  <Typography whiteSpace="nowrap">{t(`settings.admins.profile.lastActiveOn`)}</Typography>
                  <Chip label={dayjs.utc(adminData?.last_active).format(US_DATE_WITH_MONTH_NAME)}></Chip>
                  <Typography whiteSpace="nowrap"> {t(`settings.admins.profile.joinedOn`)}</Typography>
                  <Chip label={dayjs.utc(adminData?.joined_date).format(US_DATE_WITH_MONTH_NAME)}></Chip>
                  {!!adminData?.invited_by?.name && (
                    <Typography whiteSpace="nowrap">
                      {t(`settings.admins.profile.by`)} {'' + adminData?.invited_by?.name}
                    </Typography>
                  )}
                </Box>
              </Box>
            </Box>

            <Formik
              onSubmit={handleSubmitAdminDetails}
              initialValues={{
                full_name: adminData.full_name,
                username: adminData.username,
                phone_number: adminData.phone_number || '',
              }}
              validationSchema={memberValidationSchema}>
              {({ isSubmitting }) => (
                <Form>
                  <Box padding={theme.spacing(2, 2, 0)} display="flex" flexDirection="column" width={theme.spacing(60)}>
                    <TextField label="Name" name="full_name" disabled={!isCurrnetUser} isLocked={!isCurrnetUser} />
                    <TextField label="Email" name="username" disabled={!isCurrnetUser} isLocked={!isCurrnetUser} />
                    <PhoneNumberField
                      name="phone_number"
                      label="Phone Number*"
                      disabled={!isCurrnetUser}
                      isLocked={!isCurrnetUser}
                    />
                  </Box>
                  <Box padding={theme.spacing(1, 0, 2)} display="flex" justifyContent="flex-end">
                    <LoadingButton type="submit" loading={isSubmitting} variant="contained">
                      {t(`settings.admins.profile.saveChanges`)}
                    </LoadingButton>
                  </Box>
                </Form>
              )}
            </Formik>
            {isCurrnetUser && (
              <>
                <Divider sx={{ marginBottom: theme.spacing(3) }} />
                <StyledTitle>{t(`settings.admins.profile.security`)}</StyledTitle>
                <Formik
                  onSubmit={handleSubmitChangePassword}
                  initialValues={{
                    current_password: '',
                    new_password: '',
                    confirm_new_password: '',
                  }}
                  validationSchema={changePasswordValidationSchema}>
                  {({ isSubmitting }) => (
                    <Form>
                      <Box padding={theme.spacing(2, 2, 0)}>
                        <Typography paddingBottom={2}>{t(`settings.admins.profile.resetPassword`)}</Typography>
                        <PasswordField
                          label={t('settings.configurationTab.resetPassword.currentPassword')}
                          name="current_password"
                        />
                        <Box display="flex" gap={theme.spacing(1)}>
                          <PasswordField
                            label={t('settings.configurationTab.resetPassword.newPassword')}
                            name="new_password"
                            withStrengthIndicator
                          />
                          <PasswordField
                            label={t('settings.configurationTab.resetPassword.confirmNewPassword')}
                            name="confirm_new_password"
                          />
                        </Box>
                      </Box>
                      <Box display="flex" justifyContent="flex-end">
                        <LoadingButton type="submit" loading={isSubmitting} variant="contained">
                          {t(`settings.admins.profile.saveChanges`)}
                        </LoadingButton>
                      </Box>
                    </Form>
                  )}
                </Formik>
              </>
            )}
          </StyledPaper>
        )}
      </Box>
      <AdminStatusPopup
        open={disableWarningModalOpen}
        onClose={() => setDisableWarningModalOpen(false)}
        onConfirm={handleDisableAdmin}
        admin={adminData}
      />
    </>
  )
}

export const StyledPaper = styled(Paper)(() => ({
  padding: theme.spacing(3),
  margin: theme.spacing(2, 0, 0),
  minWidth: '540px',
  background: theme.palette.grey[50],
}))

export const StyledTitle = styled(Typography)(() => ({
  fontSize: 18,
  fontWeight: theme.typography.fontWeightMedium,
}))

export default AdminProfile
