import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons'
import {
  faBoxOpen,
  faComment,
  faEnvelope,
  faFishingRod,
  faMicrochipAi,
  faPenPaintbrush,
  faPenToSquare,
  faTrash,
  faHeart as heartIcon,
} from '@fortawesome/pro-light-svg-icons'
import { faHeart as fullHeartIcon } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, Button, Chip, IconButton, Typography } from '@mui/material'
import { createStyles, makeStyles, useTheme } from '@mui/styles'
import { FC, useContext, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ColumnInterface, Row } from 'react-table'

import { languages } from '@/assets/locales/languages'
import { LayoutContext } from '@/common/components/Layout/Layout'
import SimpleTable from '@/common/components/Tables/SimpleTable'
import Tags from '@/common/components/Tags/Tags'
import useNavigateWithLayoutBlocker from '@/common/hooks/useNavigateWithLayoutBlocker'
import { shortenString } from '@/common/utils/shortenString'
import { TABLE_SIZE_REFERENCE_KEYS, getDefaultTableSize, setDefaultTableSize } from '@/common/utils/table-size'
import { useAuth } from '@/context/Auth'
import {
  PhishingSimulation,
  PhishingSimulationPackage,
  PhishingSimulationPackageExtended,
} from '@/types/phishingSimulations'
import { ContentLibraryContext } from '../ContentLibrary'
import Popup from '@/common/components/Popup'
import deleteImg from '@/assets/images/popup/delete.svg'

type SimulationLibraryTableProps = {
  showPreview: (simulation: PhishingSimulation) => void
  showPreviewPackage: (simulationsPackage: PhishingSimulationPackage) => void
  deleteModule: (simulation: PhishingSimulation) => void
  launchModule: (simulation: PhishingSimulation) => void
  saveModule: (simulation: PhishingSimulation) => void
  deleteSimulationPackage: (simulationPackage: PhishingSimulationPackage) => void
  saveSimulationPackageToggle: (simulationPackage: PhishingSimulationPackage) => void
  editSimulationPackage: (simulationPackage: PhishingSimulationPackage) => void
  simulationsByTopic: {
    [name: string]: PhishingSimulation[]
  }
  savedSimulations: string[]
  savedPackages: string[]
  packages?: PhishingSimulationPackageExtended[]
}

const SimulationLibraryTable: FC<SimulationLibraryTableProps> = ({
  deleteModule,
  saveModule,
  showPreview,
  showPreviewPackage,
  simulationsByTopic = {},
  savedSimulations = [],
  deleteSimulationPackage,
  saveSimulationPackageToggle,
  editSimulationPackage,
  savedPackages = [],
  packages = [],
}) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const theme = useTheme()
  const { user } = useAuth()
  const navigate = useNavigateWithLayoutBlocker()
  const { isMultiSimulationSideMenuOpen } = useContext(ContentLibraryContext)
  const { addToSimulationPackage } = useContext(LayoutContext)

  const simulations = useMemo(() => {
    return simulationsByTopic ? Object.values(simulationsByTopic).flat() : []
  }, [simulationsByTopic])

  const defaultRowsPerPage = getDefaultTableSize(TABLE_SIZE_REFERENCE_KEYS.SIMULATIONS_TABLE)

  function handleChangeRowsPerPage(pageSize: number) {
    setDefaultTableSize(TABLE_SIZE_REFERENCE_KEYS.SIMULATIONS_TABLE, pageSize)
  }

  const columns: ColumnInterface[] = useMemo(
    () => [
      {
        Header: t('simulationLibrary.table.title'),
        accessor: 'name',
        Cell: ({ value, row }: { value: string; row: Row<PhishingSimulation> }) => {
          const simulation = row.original
          const isOrganizationOwned = simulation.organization_id === user?.current_client_id
          return (
            <Box display="flex" alignItems="center">
              <Typography>{shortenString(value, 20)}</Typography>
              {isOrganizationOwned && simulation.ai_generated && !simulation.is_public && (
                <Chip
                  size="small"
                  label={t('simulationLibrary.aiGenerated')}
                  icon={<FontAwesomeIcon icon={faMicrochipAi as IconProp}></FontAwesomeIcon>}
                />
              )}
              {isOrganizationOwned && !simulation.ai_generated && !simulation.is_public && (
                <Chip
                  size="small"
                  label={t('simulationLibrary.custom')}
                  icon={<FontAwesomeIcon icon={faPenPaintbrush as IconProp}></FontAwesomeIcon>}
                />
              )}
            </Box>
          )
        },
      },
      {
        Header: t('simulationLibrary.table.languages'),
        accessor: 'languages',
        cellProps: {
          align: 'center',
        },
        Cell: ({ value }) => {
          return !!value?.message ? (
            <Tags
              justify="center"
              useChips={false}
              tags={(value.message ?? []).map((language) => ({
                label: languages[language]?.name ?? language,
              }))}
              limit={1}
            />
          ) : (
            <></>
          )
        },
        disableSortBy: true,
      },
      {
        Header: t('simulationLibrary.table.difficulty'),
        accessor: 'difficulties',
        cellProps: {
          align: 'center',
        },
        Cell: ({ value }) => value.join(', '),
      },
      {
        Header: t('simulationLibrary.table.topics'),
        accessor: 'tags',
        cellProps: {
          align: 'center',
        },
        Cell: ({ value }) => (
          <Tags
            justify="center"
            useChips={false}
            tags={(value ?? []).map((tag) => ({
              label: tag,
            }))}
            limit={1}
          />
        ),
        disableSortBy: true,
      },
      {
        Header: t('simulationLibrary.table.type'),
        accessor: 'simulations',
        cellProps: {
          align: 'center',
        },
        Cell: ({ value }: { value: string }) => {
          const isPackage = value?.length > 1

          return isPackage ? (
            <FontAwesomeIcon icon={faBoxOpen as IconProp} fontSize={20} />
          ) : (
            <FontAwesomeIcon icon={faFishingRod as IconProp} fontSize={20} />
          )
        },
        disableSortBy: true,
      },
      {
        Header: t('simulationLibrary.table.vector'),
        accessor: 'vectors',
        cellProps: {
          align: 'center',
        },
        Cell: ({ value }: { value: string }) => {
          return (
            <Box style={{ display: 'flex', gap: '6px', justifyContent: 'center' }}>
              {value.includes('sms') && <FontAwesomeIcon icon={faComment as IconProp} fontSize={20} />}
              {value.includes('email') && <FontAwesomeIcon icon={faEnvelope as IconProp} fontSize={20} />}
              {value.includes('whatsapp') && <FontAwesomeIcon icon={faWhatsapp as IconProp} fontSize={20} />}
            </Box>
          )
        },
        disableSortBy: true,
      },
      {
        id: 'delete',
        Cell: (data) => {
          const [isDeletePopUpOpen, setIsDeletePopUpOpen] = useState(false)
          const rowItem = data.row.original
          const isPackage = rowItem.simulations?.length > 1
          if (isPackage)
            return (
              <div
                className={classes.actionsContainer}
                onClick={(e) => {
                  e.stopPropagation()
                }}>
                <Popup
                  open={isDeletePopUpOpen}
                  onClose={() => {
                    setIsDeletePopUpOpen(false)
                  }}
                  onConfirm={() => {
                    deleteSimulationPackage(rowItem)
                    setIsDeletePopUpOpen(false)
                  }}
                  buttonLabels={{
                    confirm: t('simulationPackage.deletePopup.confirmButton'),
                    cancel: t('simulationPackage.deletePopup.cancelButton'),
                  }}
                  icon={deleteImg}>
                  <h4>{t('simulationPackage.deletePopup.title')}</h4>
                  <p>{t('simulationPackage.deletePopup.message', { simulationName: rowItem.name })}</p>
                </Popup>
                <IconButton
                  className={classes.button}
                  onClick={(e) => {
                    e.stopPropagation()
                    saveSimulationPackageToggle(rowItem)
                  }}
                  size="small"
                  color={'inherit'}>
                  <FontAwesomeIcon
                    icon={(savedPackages.includes(rowItem.id) ? fullHeartIcon : heartIcon) as IconProp}
                    fontSize={22}
                    color={theme.palette.pink[500]}
                  />
                </IconButton>
                <IconButton
                  disabled={rowItem.organization_id !== user?.current_client_id}
                  className={classes.button}
                  onClick={() => {
                    setIsDeletePopUpOpen(true)
                  }}
                  size="small"
                  color={'inherit'}>
                  <FontAwesomeIcon icon={faTrash as IconProp} fontSize={22} />
                </IconButton>
                <IconButton
                  disabled={rowItem.organization_id !== user?.current_client_id}
                  className={classes.button}
                  onClick={(e) => {
                    e.stopPropagation()
                    editSimulationPackage(rowItem)
                  }}
                  size="small"
                  color={'inherit'}>
                  <FontAwesomeIcon icon={faPenToSquare as IconProp} fontSize={22} />
                </IconButton>
              </div>
            )

          return (
            <div className={classes.actionsContainer}>
              {isMultiSimulationSideMenuOpen ? (
                <Button
                  sx={{ minWidth: '88px' }}
                  onClick={(e) => {
                    addToSimulationPackage(rowItem)
                    e.stopPropagation()
                  }}
                  variant={'contained'}
                  size="small"
                  endIcon={<FontAwesomeIcon icon={faBoxOpen as IconProp} />}>
                  {t('simulationLibrary.addTo')}
                </Button>
              ) : (
                <>
                  <IconButton
                    className={classes.button}
                    onClick={(e) => {
                      e.stopPropagation()
                      saveModule(rowItem)
                    }}
                    size="small"
                    color={'inherit'}>
                    <FontAwesomeIcon
                      icon={(savedSimulations.includes(rowItem.id) ? fullHeartIcon : heartIcon) as IconProp}
                      fontSize={22}
                      color={theme.palette.pink[500]}
                    />
                  </IconButton>
                  {/* <IconButton
                disabled={(rowItem.organization_id !== user?.current_client_id) || rowItem.ai_generated}
                className={classes.button}
                onClick={(e) => {
                  e.stopPropagation()
                  navigate(`/content-library/simulations/edit/${rowItem.id}`)
                }}
                size="small"
                color={'inherit'}>
                <EditIcon />
              </IconButton> */}
                  {rowItem.organization_id === user?.current_client_id && (
                    <IconButton
                      disabled={!rowItem.ai_generated} // && !rowItem.simulations add when activate delete.
                      className={classes.button}
                      onClick={(e) => {
                        e.stopPropagation()
                        deleteModule(rowItem)
                      }}
                      size="small"
                      color={'inherit'}>
                      <FontAwesomeIcon icon={faTrash as IconProp} fontSize={22} />
                    </IconButton>
                  )}
                </>
              )}
            </div>
          )
        },
      },
    ],
    [
      t,
      user,
      classes.chip,
      classes.actionsContainer,
      classes.button,
      savedSimulations,
      theme.palette.blueGray[900],
      saveModule,
      navigate,
      deleteModule,
    ]
  )

  const handleOpenPreview = ({ original }: Row<PhishingSimulation | PhishingSimulationPackage>) => {
    if (!original?.id) {
      return
    } else if ((original as PhishingSimulationPackage).simulations) {
      showPreviewPackage(original as PhishingSimulationPackage)
    } else {
      showPreview(original as PhishingSimulation)
    }
  }

  return (
    <div className={classes.root}>
      <SimpleTable
        onRowClick={handleOpenPreview}
        columns={columns}
        data={[...simulations, ...packages]}
        setData={() => {}}
        noResultsContent={t('library.noResults')}
        enableCheckbox={false}
        initialPageSize={defaultRowsPerPage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        draggabledSimulationRows={true && isMultiSimulationSideMenuOpen}
      />
    </div>
  )
}

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      padding: theme.spacing(2, 0, 4),
    },
    paper: {
      padding: theme.spacing(0, 3),
      '& .MuiTableCell-head': {
        borderWidth: 2,
      },
    },
    actionsContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
    },
    button: {
      margin: theme.spacing(0, 0.5),
      color: theme.palette.blueGray[900],
    },
  })
)

export default SimulationLibraryTable
