import { PagedQuery } from './common'
import { Sender } from './phishingSimulations'
//TODO Find better place for this file

//TODO This should be a const
export const PER_USER = 'per_user'

export enum AssetType {
  training_video = 'training_video',
  phishing_simulation = 'phishing_simulation',
}

export enum CampaignStatus {
  pending = 'pending', // before start date
  processing = 'processing', // creating email schedules (system status)
  scheduled = 'scheduled', // schelueles created but no email sent yet (system status)
  in_progress = 'in_progress', // active campaing
  completed = 'completed', // after end date
  canceled = 'canceled', // by user
  error = 'error', // e.g. error in creating email schedules
  quota_error = 'quota_error', // e.g. quota limitation reached
}

export enum CampaignScore {
  bad = 'bad',
  low = 'low',
  average = 'average',
  excellent = 'excellent',
}

export type CampaignDifficulty = '1' | '2' | '3' | '4' | '5'

export enum AssetAssignmentType {
  targeted = 'targeted',
  single_random = 'single_random',
  random_per_user = 'random_per_user',
}

export type Targets = {
  groups: { [id: string]: AssetAssignmentType.single_random | AssetAssignmentType.single_random | 'string' }
  dynamic_groups: { [id: string]: AssetAssignmentType.single_random | AssetAssignmentType.single_random | 'string' }
  offices: { [id: string]: AssetAssignmentType.single_random | AssetAssignmentType.single_random | 'string' }
  users: { [id: string]: AssetAssignmentType.single_random | AssetAssignmentType.single_random | 'string' }
}

export enum AssetAssignmentFallback {
  single_random = 'single_random',
  random_per_user = 'random_per_user',
}

export type AssetAssignmentLogic = {
  type: AssetAssignmentType
  targets?: Targets
  fallback?: AssetAssignmentFallback
}

export type Schedule = {
  start: string
  end: string
  start_tz?: string
  end_tz?: string
  send_on_start?: boolean
  send_over_days?: number
}

export type CampaignRequest = {
  name: string
  users: string[]
  offices: string[]
  dynamic_groups: string[]
  groups: string[]
  asset_type: string
  asset_ids: string[]
  asset_metadata?: Record<string, any>
  asset_assignment_logic: AssetAssignmentLogic
  schedule: Schedule
  is_test?: boolean
  custom_microtraining_urls?: Record<string, string>
}

export type Campaign = CampaignRequest & {
  _id: string
  status: CampaignStatus
  client_id: string
  users_count: number
  score: number
  bamboohr_training_id: number
  created_by: string
  created_at: string
  updated_at: string
}

export type CampaignListItem = {
  _id: string
  name: string
  status: string
  users_count: number
  asset_type: AssetType
  score: string
  is_test: boolean
  start_date: string
  start_tz: string
  end_date: string
  end_tz: string
  asset_metadata: {
    include_quiz?: boolean
    difficulty?: number
  }
  asset_ids: string[]
}

export type CampaignUserEvents = {
  sms_link_click: string | boolean
  email_link_click: string | boolean
  email_open: string | boolean
  submit: string | boolean
  report: string | boolean
  feedback_link_click: string | boolean
  feedback_completed: string | boolean
  feedback_acknowledged: string | boolean
  feedback_tab_closed: string | boolean
  feedback_page_iteration: string | boolean
  show_feedback_tips: string | boolean
  feedback_opened: string | boolean
  training_paused: string | boolean
  training_completed: string | boolean
  training_closed: string | boolean
  training_opened: string | boolean
  inserted_data: string | boolean
  quiz_started: string | boolean
  quiz_paused: string | boolean
  quiz_continued: string | boolean
  quiz_completed: string | boolean
  quiz_answer: string | boolean
  email_reply: string | boolean
  video_page_load: string | boolean
  video_running: string | boolean
  video_tab_blur: string | boolean
  video_tab_focus: string | boolean
  video_tab_close: string | boolean
  subtitle_change: string | boolean
  expired_campaign_link_click: string | boolean
  campaign_started: string | boolean
  campaign_sent: string | boolean
}

export type CampaignUser = {
  _id: string
  username: string
  first_name: string
  last_name: string
  member_of: string[]
  campaign_score: number
  events: CampaignUserEvents
}

export type MicrotrainingIndicator = {
  selector: string
  title: string
  description: string
}

export type CampaignMicrotraining = {
  sender: Sender
  subject: string
  logo: string
  message: {
    html: string
    language: string
  }
  landing_page: {
    html: string
    language: string
  }
  indicators: {
    message: MicrotrainingIndicator[]
    landing_page: MicrotrainingIndicator[]
  }
  token: string
  vector?: string
}

export enum CampaignQuerySort {
  start_date = 'start_date',
  end_date = 'end_date',
  name = 'name',
  users_count = 'users_count',
  score = 'score',
}

export type CampaignListQuery = PagedQuery<CampaignQuerySort> & {
  name: string
  start_date: string
  end_date: string
  score: CampaignScore[]
  status: CampaignStatus[]
  asset_type: AssetType[]
  difficulty: CampaignDifficulty[]
  is_test: boolean[]
}
