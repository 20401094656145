import { FC, useState } from 'react'
import { useTheme, styled } from '@mui/material/styles'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import { useParams } from 'react-router-dom'
import LoadingContainer from '@/common/components/LoadingContainer/LoadingContainer'
import { Button, Grid, Paper, Typography, Box } from '@mui/material'
import NoResultsContainer from '@/common/components/NoResultsContainer/NoResultsContainer'
import { useTranslation } from 'react-i18next'
import { useOffices } from '@/api/offices/offices'
import Tags from '@/common/components/Tags/Tags'
import { useGroups } from '@/api/groups/groups'
import EngagementChart from './EngagementChart'
import ExposureChart from './ExposureChart'
import UserCampaignsTable from './UserCampaignsTable'
import EditUserModal from '@/common/components/EditUserModal/EditUserModal'
import useToast from '@/common/hooks/useToast'
import { faPhone, faEnvelope } from '@fortawesome/pro-light-svg-icons'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import CustomBreadcrumbs from '@/common/components/Breadcrumbs/Breadcrumbs'
import AwarenessScore from '@/common/components/AwarenessScore/AwarenessScore'
import { useMemberById } from '@/api/members/getMember'

import { theme } from '@/theme/theme'
import useEditMember from '@/api/members/edit'
import { Member } from '@/types/members'
import { getErrorMessage } from '@/api/utils/get-error'

const UserProfile: FC = () => {
  const classes = useStyles()
  const theme = useTheme()
  const { _id } = useParams()
  const { data: memberData, isLoading } = useMemberById({ id: _id })
  const { mutateAsync: editMember } = useEditMember()

  const { data: offices } = useOffices()
  const { data: groups } = useGroups()
  const userGroups = groups && memberData ? groups.filter((group) => memberData.member_of.includes(group._id)) : []
  const [userModalIsOpen, setUserModalIsOpen] = useState(false)
  const { successToast, errorToast } = useToast()
  const { t } = useTranslation()

  function handleOpenModal() {
    setUserModalIsOpen(true)
  }

  async function handleEditUser(member: Partial<Member>) {
    try {
      await editMember({ _id: member._id, memberPayload: member })
      successToast(t('userProfile.userEdited'))
    } catch (e) {
      const errorMessage = getErrorMessage(e)
      errorToast(errorMessage || t('users.errors.faliedEditUser'))
      throw e
    }
  }

  return (
    <div className={classes.root}>
      <StyledBreadcrumbBox>
        <CustomBreadcrumbs lastBreadcrumbSuffix={`| ${memberData?.first_name}` || ''} />
      </StyledBreadcrumbBox>
      <div className={classes.header}>
        <div className={classes.flexContainer}>
          <div className={classes.titleContainer}>
            <Typography className={classes.title} gutterBottom variant={'h5'}>
              {memberData ? `${memberData.first_name} ${memberData.last_name} ` : ''}
              {offices && memberData ? `| ${offices?.byId[memberData.office_id]?.name}` : ''}
            </Typography>

            <Typography className={classes.title} gutterBottom variant={'subtitle1'} color={'textSecondary'}>
              {memberData ? (
                <>
                  <FontAwesomeIcon icon={faEnvelope as IconProp} className={classes.icon} />
                  {memberData.username}
                </>
              ) : (
                ''
              )}
            </Typography>

            <Typography className={classes.title} gutterBottom variant={'subtitle1'} color={'textSecondary'}>
              {memberData?.phone_number ? (
                <>
                  <FontAwesomeIcon icon={faPhone as IconProp} className={classes.icon} />
                  {memberData.phone_number}
                </>
              ) : (
                ''
              )}
            </Typography>
          </div>
          <EditUserModal
            saveUserEdit={handleEditUser}
            open={userModalIsOpen}
            setOpen={setUserModalIsOpen}
            user={memberData}
            onClose={() => {}}
          />
          <Button
            color={'inherit'}
            sx={{ borderColor: theme.palette.cyan[500] }}
            variant={'outlined'}
            onClick={handleOpenModal}>
            {t('userProfile.editUser')}
          </Button>
        </div>
        <Tags
          tags={userGroups.map((group) => ({
            label: group.name,
            color: theme.palette.background.secondaryGradient,
          }))}
          limit={4}
        />
      </div>

      {isLoading ? (
        <LoadingContainer />
      ) : memberData ? (
        <Grid container spacing={2} alignItems={'stretch'}>
          <Grid item sm={4} xs={12}>
            <Paper className={classes.leftPanel}>
              <div className={classes.lineChartContainer}>
                <AwarenessScore
                  score={memberData?.awareness_score || 0}
                  monthlyScores={memberData?.awareness_last_30_days}
                  loading={isLoading}
                />
              </div>
              <Typography gutterBottom variant={'h6'}>
                {t('userProfile.exposureSegments')}
              </Typography>
              <div className={classes.exposureContainer}>
                <ExposureChart id={_id ?? ''} />
              </div>
            </Paper>
          </Grid>
          <Grid item sm={8} xs={12} container alignItems={'flex-start'}>
            <Grid container spacing={2} alignItems={'flex-start'}>
              <Grid item xs={12}>
                <Paper className={classes.engagementContainer}>
                  <EngagementChart id={_id ?? ''} />
                </Paper>
              </Grid>
              <Grid item xs={12}>
                <Paper className={classes.campaignsContainer}>
                  <UserCampaignsTable userId={_id ?? ''} />
                </Paper>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <NoResultsContainer text={'userProfile.noData'} />
      )}
    </div>
  )
}

const StyledBreadcrumbBox = styled(Box)(() => ({
  marginBottom: theme.spacing(2),
}))

const useStyles = makeStyles((theme) =>
  createStyles({
    root: { height: '100%', width: '100%', padding: theme.spacing(1, 4, 4) },
    header: {
      marginBottom: theme.spacing(3),
    },
    flexContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',
    },
    titleContainer: { display: 'flex', alignItems: 'center' },
    title: {
      marginRight: theme.spacing(2),
      display: 'flex',
      alignItems: 'center',
    },
    icon: { marginRight: theme.spacing(1) },
    topRow: { height: 200, width: '100%' },
    leftPanel: { height: '100%', width: '100%', padding: theme.spacing(2) },
    lineChartContainer: {
      height: 240,
      width: '100%',
      position: 'relative',
      marginBottom: theme.spacing(2),
    },
    engagementContainer: { height: 240, padding: theme.spacing(2) },
    exposureContainer: { height: 410, width: '100%' },
    campaignsContainer: { padding: theme.spacing(2) },
  })
)
export default UserProfile
